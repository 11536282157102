<template>
  <component
    :is="componentType"
    :to="item.to || null"
    :href="!item.to ? item.href || null : undefined"
    :class="noUnderline ? '' : 'underline hover:no-underline'"
    :target="target"
    :rel="rel"
    @click="$emit('click')"
    ><span
      ><slot>{{ item.title }}</slot></span
    >
    <icon
      v-if="item.external"
      name="IconNewWindow"
      class="nav-icon-window ml-2 text-xs"
      :class="iconClass"
  /></component>
</template>

<script>
import { resolveComponent } from 'vue'

export default {
  name: 'TafeLink',
  props: {
    item: {
      type: Object,
      required: true
    },
    noUnderline: {
      type: Boolean,
      default: false
    },
    iconClass: {
      type: String,
      default: ''
    }
  },
  emits: ['click'],
  computed: {
    componentType() {
      return this.item?.to ? resolveComponent('NuxtLink') : 'a'
    },
    target() {
      return this.item?.external ? '_blank' : undefined
    },
    rel() {
      return this.item?.external ? 'noopener noreferrer' : undefined
    }
  }
}
</script>
